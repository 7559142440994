import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	FormControl,
	Heading,
	Switch,
	FormLabel,
	Image,
	Text,
	Input,
} from '@chakra-ui/react';
import { uploadFile } from 'src/services/fileUpload';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { ArrowBackIcon } from '@chakra-ui/icons';
import TemplatesContext, {
	ILayerSpec,
	ITemplate,
	IVariation,
	Kind,
} from 'src/contexts/templates/TemplatesContext';
import {
	updateTemplate,
	createTemplate,
	getTemplateById,
	getFonts,
} from 'src/services/templates';
import { getIndustries } from 'src/services/industries';
import { toastError, toastSuccess } from 'src/services/toast';
import {
	MultiSelectValuesHook,
	StringInputHook,
	MultiSelectCreateableInputHook,
} from 'src/components/common/form';
import WrappedVariationsList from './VariationsList';
import UserContext from 'src/contexts/UserContext';
import TestTemplates from '../testTemplates/TestTemplates';
import { AttributesSection } from './commponents/AtributesSection';
import TagInputHook from 'src/components/common/form/TagsCreateableInput/TagInputHook';
import { template } from 'lodash';

const TemplateSchema = z.object({
	id: z.string().min(1, { message: 'Required' }),
	enabled: z.boolean(),
	attributes: z
		.object({
			thumbnail: z.string().url().optional(),
			fonts: z.array(z.string()).optional(),
			mainIndustry: z.string().min(1, { message: 'Required' }),
			industries: z.array(z.string()).optional(),
			tags: z.array(z.string()).optional(),
			season: z.string().optional(),
			style: z.string().min(1, { message: 'Required' }),
			kind: z.enum(['Product', 'Service']),
			priority: z.number().min(1).max(99).default(5),
		})
		.optional(),
	variations: z
		.array(
			z.object({
				id: z.string().optional(),
				layeredFile: z.string().optional(),
				layerSpecs: z
					.array(
						z.object({
							name: z.string().optional(),
							actions: z
								.array(
									z.object({
										id: z.string().optional(),
										arguments: z.any().optional(),
									}),
								)
								.optional(),
							dimensions: z
								.object({
									width: z.number().optional(),
									height: z.number().optional(),
								})
								.optional(),
							position: z
								.object({
									left: z.number().optional(),
									top: z.number().optional(),
								})
								.optional(),
						}),
					)
					.optional(),
			}),
		)
		.optional(),
	driveFolderId: z.string().optional(),
});

export type TemplateFormValues = z.infer<typeof TemplateSchema>;

const TemplatesForm: React.FC = () => {
	const location = useLocation();
	const templateData = location.state?.template;

	const navigate = useNavigate();
	const { id: templateId } = useParams<{ id: string }>();
	const { templates, selectedTemplate, configuredLayers, setSelectedTemplate } =
		useContext(TemplatesContext);
	const { user } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const [fontOptions, setFontOptions] = useState<
		{ label: string; value: string }[]
	>([]);
	const [industryOptions, setIndustryOptions] = useState<
		{ label: string; value: string }[]
	>([]);

	const [scope, setScope] = useState<ITemplate['scope']>(templateData?.scope);

	const formMethods = useForm<TemplateFormValues>({
		resolver: zodResolver(TemplateSchema),
		defaultValues: {
			id: '',
			enabled: true,
			attributes: {
				kind: 'Service',
				priority: 5,
			},
			variations: [],
		},
		mode: 'onSubmit',
	});
	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors },
		clearErrors,
	} = formMethods;

	const attributes = watch('attributes');

	const folderName = watch('id');
	const [isUploading, setIsUploading] = useState(false);

	const handleVariationsGenerated = (variationsData: any) => {
		setValue('variations', variationsData);
		setValue('driveFolderId', variationsData.templateFolderId);
	};

	useEffect(() => {
		const values = formMethods.getValues();

		const newTemplate: ITemplate = {
			...values,
			id: values.id || 'No title',
			variations: values.variations || [],
			attributes: {
				...values.attributes,
				season: values.attributes?.season,
			},
		};

		setSelectedTemplate(newTemplate);
	}, [watch('id'), watch('variations')]);

	useEffect(() => {
		if (templateData) {
			if (templateData.scope) {
				setScope(templateData.scope);
			}
			reset({ ...templateData });
		}
	}, [templateData, reset]);

	const normalizeKind = (kind?: string): Kind => {
		return kind?.toLowerCase() === 'product' ||
			kind?.toLowerCase() === 'service'
			? ((kind.charAt(0).toUpperCase() + kind.slice(1).toLowerCase()) as Kind)
			: 'Service';
	};

	useEffect(() => {
		const loadTemplateData = async () => {
			if (templateId && user?.account) {
				setIsLoading(true);
				try {
					const updatedTemplate = await getTemplateById(
						user.account,
						templateId,
						templateData.scope,
					);

					setSelectedTemplate(updatedTemplate);
					const formattedFonts = updatedTemplate.attributes?.fonts?.map(
						(font) => font.replace(/-/g, ' '),
					);

					const [mainIndustry, ...formattedIndustries] =
						updatedTemplate.attributes?.industries || [];

					const normalizedKind = normalizeKind(
						updatedTemplate.attributes?.kind,
					);

					const formattedVariations = updatedTemplate.variations?.map(
						(variation) => ({
							...variation,
							layerSpecs: Array.isArray(variation.layerSpecs)
								? variation.layerSpecs.filter(
										(layerSpec) => typeof layerSpec === 'object',
								  )
								: [],
						}),
					);

					let tagsArray;
					if (
						updatedTemplate.attributes?.tags &&
						typeof updatedTemplate.attributes?.tags === 'string'
					) {
						tagsArray = (updatedTemplate.attributes.tags as string)
							.split(',')
							.map((tag: string) => tag.trim());
					} else {
						tagsArray = updatedTemplate.attributes?.tags;
					}

					reset({
						id: updatedTemplate.id,
						enabled: updatedTemplate.enabled,
						attributes: {
							...updatedTemplate.attributes,
							fonts: formattedFonts,
							mainIndustry: mainIndustry,
							industries: formattedIndustries,
							tags: tagsArray || [],
							kind: normalizedKind,
							priority: updatedTemplate.attributes?.priority || 5,
						},
						variations: formattedVariations || [],
					});
				} catch (error) {
					console.error('Failed to load template data:', error);
					toastError('Failed to load template data');
				} finally {
					setIsLoading(false);
				}
			}
		};
		loadTemplateData();
	}, [templateId, user, setSelectedTemplate, reset, setValue]);

	useEffect(() => {
		const loadTemplateData = async () => {
			if (templateId && user?.account) {
				setIsLoading(true);
				try {
					const updatedTemplate = await getTemplateById(
						user.account,
						templateId,
						templateData.scope,
					);

					setSelectedTemplate(updatedTemplate);
					const formattedFonts = updatedTemplate.attributes?.fonts?.map(
						(font) => font.replace(/-/g, ' '),
					);

					const [mainIndustry, ...formattedIndustries] =
						updatedTemplate.attributes?.industries || [];

					const normalizedKind = normalizeKind(
						updatedTemplate.attributes?.kind,
					);

					const formattedVariations = updatedTemplate.variations?.map(
						(variation) => ({
							...variation,
							layerSpecs: Array.isArray(variation.layerSpecs)
								? variation.layerSpecs.filter(
										(layerSpec) => typeof layerSpec === 'object',
								  )
								: [],
						}),
					);
					let tagsArray;
					if (
						updatedTemplate.attributes?.tags &&
						typeof updatedTemplate.attributes?.tags === 'string'
					) {
						tagsArray = (updatedTemplate.attributes.tags as string)
							.split(',')
							.map((tag: string) => tag.trim());
					} else {
						tagsArray = updatedTemplate.attributes?.tags;
					}

					reset({
						id: updatedTemplate.id,
						enabled: updatedTemplate.enabled,
						attributes: {
							...updatedTemplate.attributes,
							fonts: formattedFonts,
							mainIndustry: mainIndustry,
							industries: formattedIndustries,
							tags: tagsArray || [],
							kind: normalizedKind,
							priority: updatedTemplate.attributes?.priority || 5,
						},
						variations: formattedVariations || [],
					});
					if (updatedTemplate.attributes) {
						if (updatedTemplate.attributes.fonts) {
							setValue('attributes.fonts', formattedFonts);
						}
						if (updatedTemplate.attributes.industries) {
							setValue('attributes.mainIndustry', mainIndustry);
							setValue('attributes.industries', formattedIndustries);
						}
					}
				} catch (error) {
					console.error('Failed to load template data:', error);
					toastError('Failed to load template data');
				} finally {
					setIsLoading(false);
				}
			}
		};
		loadTemplateData();
	}, [templateId, user, setSelectedTemplate, reset, setValue]);

	useEffect(() => {
		if (templateId && templates.length > 0) {
			const template = templates.find((t) => t.id === templateId) || null;
			setSelectedTemplate(template);
			if (template) {
				const formattedFonts = template.attributes?.fonts?.map((font) =>
					font.replace(/-/g, ' '),
				);

				const [mainIndustry, ...formattedIndustries] =
					template.attributes?.industries || [];

				const formattedVariations = template.variations?.map((variation) => ({
					...variation,
					layerSpecs: Array.isArray(variation.layerSpecs)
						? variation.layerSpecs.filter(
								(layerSpec) => typeof layerSpec === 'object',
						  )
						: [],
				}));

				reset({
					id: template.id,
					enabled: template.enabled,
					attributes: {
						...template.attributes,
						fonts: formattedFonts,
						mainIndustry: mainIndustry || '',
						industries: formattedIndustries,
					},
					variations: formattedVariations || [],
				});

				if (template.attributes) {
					if (template.attributes.fonts) {
						setValue('attributes.fonts', formattedFonts);
						setFontOptions(
							template.attributes.fonts.map((font) => ({
								label: font.replace(/-/g, ' '),
								value: font,
							})),
						);
					}
					if (template.attributes.industries) {
						setValue('attributes.mainIndustry', formattedIndustries[0]);
						setValue('attributes.industries', formattedIndustries);
						setIndustryOptions(
							formattedIndustries.map((ind) => ({ label: ind, value: ind })),
						);
					}
				}
			}
		} else {
			if (!template) {
				setSelectedTemplate(null);
				reset({
					id: '',
					enabled: true,
					attributes: {},
					variations: [],
				});
			}
		}
	}, [templateId, templates, setSelectedTemplate, reset, setValue]);

	useEffect(() => {
		const fetchFonts = async () => {
			try {
				const fontsData = await getFonts();
				setFontOptions(
					fontsData.map((font) => ({
						label: font.replace(/-/g, ' '),
						value: font,
					})),
				);
			} catch (error) {
				console.error('Failed to fetch fonts', error);
			}
		};

		const fetchIndustries = async () => {
			try {
				const industries = await getIndustries();
				const formattedIndustries = industries.map((industry) => ({
					label: industry.name,
					value: industry.industryIds[0],
				}));
				setIndustryOptions(formattedIndustries);
			} catch (error) {
				console.error('Failed to fetch industries', error);
			}
		};
		fetchFonts();
		fetchIndustries();
	}, []);

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (!file) return;

		setIsUploading(true);
		try {
			const { imageUrl } = await uploadFile(
				file,
				false,
				100,
				100,
				undefined,
				undefined,
			);
			setValue('attributes.thumbnail', imageUrl);
		} catch (error) {
			console.error('Error uploading file', error);
			toastError('Failed to upload image');
		}
		setIsUploading(false);
	};

	const processConfiguredLayers = (
		configuredLayers: { variationId: string; layerSpec: ILayerSpec[] }[],
		selectedTemplate: ITemplate,
	): IVariation[] => {
		return (selectedTemplate.variations || []).map((variation) => {
			const matchingConfiguredLayer = configuredLayers.find(
				(configuredLayer) => configuredLayer.variationId === variation.id,
			);

			if (matchingConfiguredLayer) {
				const processedLayerSpecs = matchingConfiguredLayer.layerSpec.reduce(
					(acc: ILayerSpec[], curr: ILayerSpec) => {
						const existingLayerSpec = acc.find(
							(spec) => spec.name === curr.name,
						);

						if (existingLayerSpec) {
							curr.actions?.forEach((action) => {
								const hasSameAction = existingLayerSpec.actions?.some(
									(existingAction) =>
										existingAction.id === action.id &&
										JSON.stringify(existingAction.arguments) ===
											JSON.stringify(action.arguments),
								);

								if (!hasSameAction) {
									existingLayerSpec.actions = [
										...(existingLayerSpec.actions || []),
										action,
									];
								}
							});
						} else {
							acc.push(curr);
						}

						return acc;
					},
					[],
				);

				return { ...variation, layerSpecs: processedLayerSpecs };
			}

			return variation;
		});
	};

	const onSubmit = async (data: TemplateFormValues) => {
		try {
			const industries = [...(data.attributes!.industries || [])];
			if (data.attributes!.mainIndustry) {
				industries.unshift(data.attributes!.mainIndustry);
			}

			const filteredAttributes = {
				...data.attributes,
				industries,
				mainIndustry: undefined,
			};

			const updatedVariations = processConfiguredLayers(
				configuredLayers,
				selectedTemplate!,
			);

			if (templateId) {
				const updatedTemplate: ITemplate = {
					...selectedTemplate!,
					...data,
					attributes: filteredAttributes,
					id: data.id || templateId,
					variations: updatedVariations,
				};

				await updateTemplate(updatedTemplate, templateId, scope!);

				toastSuccess('Template updated successfully');
			} else {
				const newTemplate: ITemplate = {
					...data,
					attributes: filteredAttributes,
					id: data.id || 'No title',
					variations: updatedVariations,
					scope: scope,
				};
				await createTemplate(newTemplate);
				toastSuccess('Template created successfully');
			}

			navigate('/account/templates');
		} catch (error) {
			console.error('Failed to submit template', error);
			toastError(error);
		}
	};

	const handleBack = () => {
		navigate('/account/templates');
	};

	if (isLoading) {
		return <Box>Loading...</Box>;
	}

	const textButton = templateId ? 'Save Template' : 'Create new template';

	return (
		<>
			<Box justifyContent="center" mx="auto" maxW="850px">
				<FormProvider {...formMethods}>
					<form
						onSubmit={handleSubmit((data) => {
							onSubmit(data);
						})}
						id="hook-form"
					>
						<Flex
							direction="column"
							gap={4}
							width="850px"
							marginBottom="80px"
							justifyContent="center"
						>
							<Button
								onClick={handleBack}
								leftIcon={<ArrowBackIcon />}
								justifyContent="left"
								variant="link"
								colorScheme="blue"
								mb={4}
							>
								Back
							</Button>
							<Flex
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								width="100%"
							>
								<Heading as="h2" size="lg" width="100%">
									{templateId ? 'Edit Template' : 'New Template'}
								</Heading>
								<FormControl
									display="flex"
									alignItems="center"
									justifyContent="right"
									mr="20px"
								>
									<FormLabel htmlFor="enabled-switch" mb="0">
										Enabled
									</FormLabel>
									<Switch
										id="enabled-switch"
										isChecked={watch('enabled')}
										onChange={() => setValue('enabled', !watch('enabled'))}
									/>
								</FormControl>
							</Flex>
							<Flex direction="row" gap={4}>
								<Flex direction="column" flex="1" gap={4}>
									<FormControl maxW="400px">
										<StringInputHook name={'id'} label={'Name'} required />
									</FormControl>
									<FormControl maxW="400px">
										<FormLabel>Fonts</FormLabel>
										<MultiSelectCreateableInputHook
											name="attributes.fonts"
											placeholder="Select/search fonts"
											options={fontOptions}
											isLoading={false}
											disableCreateOption={true}
											isMulti
											required
										/>
										<MultiSelectValuesHook
											name="attributes.fonts"
											title="Selected Fonts"
											options={fontOptions}
										/>
										<FormControl maxW="400px" mt="20px">
											<FormLabel>Tags</FormLabel>
											<TagInputHook
												name="attributes.tags"
												placeholder="Add tags"
											/>
										</FormControl>
										<MultiSelectValuesHook
											name="attributes.tags"
											title="Selected Tags"
											options={(() => {
												const tags = watch('attributes.tags');
												if (typeof tags === 'string') {
													const parsedTags = (tags as string)
														.split(',')
														.map((tag) => ({
															label: tag.trim(),
															value: tag.trim(),
														}));

													return parsedTags;
												}

												if (Array.isArray(tags)) {
													const parsedTags = tags.map((tag) => ({
														label: tag.trim(),
														value: tag.trim(),
													}));
													return parsedTags;
												}

												return [];
											})()}
										/>
									</FormControl>
									<AttributesSection industryOptions={industryOptions} />
								</Flex>
								<Flex
									direction="column"
									alignItems="center"
									justifyContent="right"
									flex="1"
									gap={4}
								>
									<FormControl width="300px" ml="80px">
										<FormLabel>Thumbnail</FormLabel>
										{attributes?.thumbnail ? (
											<Box mt={2} width="300px" height="300px" mb="10px">
												<Image
													src={attributes.thumbnail}
													alt="Thumbnail Preview"
													width="100%"
													height="100%"
													objectFit="cover"
												/>
											</Box>
										) : (
											<Box
												mt={2}
												mb="10px"
												width="300px"
												height="300px"
												bg="gray.200"
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Box width="300px" height="300px" bg="gray.400"></Box>
											</Box>
										)}
										<Flex justifyContent="flex-end">
											<input
												type="file"
												accept=".png, .jpg, .jpeg, .gif, .webp, .svg, .avif"
												onChange={handleFileChange}
												style={{ display: 'none' }}
												id="file-upload"
											/>
											<Button
												as="label"
												htmlFor="file-upload"
												isLoading={isUploading}
												loadingText="Uploading..."
											>
												{attributes?.thumbnail
													? 'Change image'
													: 'Upload image'}
											</Button>
										</Flex>
									</FormControl>
								</Flex>
							</Flex>
						</Flex>
						<WrappedVariationsList
							onVariationsGenerated={handleVariationsGenerated}
							folderName={folderName || 'New Folder'}
						/>
						<Flex justifyContent="flex-end"></Flex>
					</form>
					<Box>
						<TestTemplates textButton={textButton} />
					</Box>
				</FormProvider>
			</Box>
		</>
	);
};

export default TemplatesForm;
